* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

:root {
	--primary: #4d65d8;
	--secondly: #5168c1;
}

body {
	font-family: 'Poppins', sans-serif;
	overflow: hidden;
}

.wave {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: -1;
}

.container {
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 7rem;
	padding: 0 2rem;
}

.img {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.login-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}

.card-3d-wrapper {
	transform-style: preserve-3d;
	transition: all 600ms ease-out;
}

.card-change {
	transform: rotateY(180deg);
}

.card {
	backface-visibility: hidden;
	width: 360px;
	position: relative;
}

.card-front {
	transform: rotateY(180deg);
}

.card-back {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 20;
}

.img img {
	width: 500px;
}

.card img {
	height: 100px;
}

.card h2 {
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.card .input-div {
	position: relative;
	display: grid;
	grid-template-columns: 7% 93%;
	margin: 25px 0;
	padding: 5px 0;
	border-bottom: 2px solid #d9d9d9;
}

.input-div.one {
	margin-top: 0;
}

.input-div.code {
	grid-template-columns: 7% 63% 30%;
	margin-bottom: 4px;
}

.i {
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i {
	transition: .3s;
}

.input-div>div {
	position: relative;
	height: 45px;
}

.input-div>div>h5 {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}

.input-div>div>span {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: var(--primary);
	font-size: 14px;
	transition: .3s;
}

.input-div.code>div>span {
	cursor: pointer;
}

.input-div:before,
.input-div:after {
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: var(--primary);
	transition: .4s;
}

.input-div:before {
	right: 50%;
}

.input-div:after {
	left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
	width: 50%;
}

.input-div.focus>div>h5 {
	top: -5px;
	font-size: 12px;
}

.input-div.focus>.i>i {
	color: var(--primary);
}

.input-div>div>input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-div.pass {
	grid-template-columns: 7% 86% 7%;
	margin-bottom: 4px;
}

.card .bottom-div {
	position: relative;
	display: grid;
	grid-template-columns: 50% 50%;
	padding-top: 12px;
	padding-bottom: 12px;
}

.left {
	display: block;
	text-align: left;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
	cursor: pointer;
}

.right {
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
	cursor: pointer;
}

.left:hover,
.right:hover {
	color: var(--primary);
}

.btn {
	display: block;
    width: 100%;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, var(--secondly), var(--primary), var(--secondly));
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: .5s;
    height: auto;
    text-align: center;
    padding: 7px 12px; 
}

.btn:hover {
	background-position: right;
}

.message { 
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 96px;
    max-width: 70%;
    max-height: 70%;
    overflow: auto;
    color: white;
    word-break: break-all;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    pointer-events: all;
    font-size: 15px;
    line-height: 1.5;
    box-sizing: border-box;
    padding: 12px;
}

@media screen and (max-width: 1050px) {
	.container {
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px) {
	.card {
		width: 290px;
	}

	.card h2 {
		font-size: 2.4rem;
		margin: 8px 0;
	}

	.img img {
		width: 400px;
	}
}

@media screen and (max-width: 900px) {
	.container {
		grid-template-columns: 1fr;
	}

	.img {
		display: none;
	}

	.wave {
		display: none;
	}

	.login-content {
		justify-content: center;
	}
}