@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1649666012120') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

.icon-phone:before {
  content: "\e652";
}

.icon-lock:before {
  content: "\e6c0";
}

.icon-people:before {
  content: "\e736";
}

.icon-attention:before {
  content: "\e73d";
}

.icon-attentionforbid:before {
  content: "\e7b2";
}

